import { isMobile } from "react-device-detect";
import "./Header.css";
import { default as headerImg } from "../img/header.jpg";

function Header() {
  return (
    <div>
      <div className="header-scroll" id="header"></div>
      {isMobile ? (
        <header>
          <div className="header-text-mobile">
            <div>
              <p>REGENERON THERAPEUTICS INC</p>
              <h2>
                IS A STARTUP STAGE BIOTECH COMPANY THAT DISCOVERS AND DEVELOPS
                NEXT GENERATION GENE THERAPY SOLUTIONS TO TREAT INHERITED
                RETINAL DISEASES AND NEURODEGENERATIVE DISORDERS. OUR APPROACH
                HAS THE POTENTIAL TO MAKE GENE THERAPY AFFORDABLE AND EXTENDED
                APPLICATIONS TO VARIOUS GENETIC DISEASES.
              </h2>
            </div>
          </div>
          <img className="header-img-mobile" src={headerImg} alt="Header" />
        </header>
      ) : (
        <header>
          <div className="header-text">
            <h1>REGENERON THERAPEUTICS INC</h1>
            <h2>
              IS A STARTUP STAGE BIOTECH COMPANY THAT DISCOVERS AND DEVELOPS
              NEXT GENERATION GENE THERAPY SOLUTIONS TO TREAT INHERITED RETINAL
              DISEASES AND NEURODEGENERATIVE DISORDERS. OUR APPROACH HAS THE
              POTENTIAL TO MAKE GENE THERAPY AFFORDABLE AND EXTENDED
              APPLICATIONS TO VARIOUS GENETIC DISEASES.
            </h2>
          </div>
          <div className="image-container-header-desktop">
            <img src={headerImg} alt="Header" />
          </div>
        </header>
      )}
    </div>
  );
}
export default Header;
