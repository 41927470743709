import { isMobile } from "react-device-detect";
import "./Technology.css";
import Card from "./Card";
import { default as eyeImg } from "../img/remove_red_eye_48dp.svg";
import { default as biotechImg } from "../img/biotech_48dp.svg";
import { default as scienceImg } from "../img/science_48dp.svg";

function Technology() {
  return (
    <div className="technology technology-scroll main-section" id="technology">
      <h2 className="main-title text-center">TECHNOLOGY</h2>

      {isMobile ? (
        <div className="container card-cover">
          <div className="technology-col mb-2">
            <Card
              title="OUR VISION AND MISSION"
              img={eyeImg}
              text="Regeneron Therapeutics’ approach abandons traditional usage of viral vectors, which makes treatment intolerably expensive. We believe that our novel delivery methods and cargos will reduce development and manufacturing cost to an affordable range. We aim to provide gene therapies with price no higher than a SARS-CoV-2 jab to cover high unmet genetic treatment needs."
            />
            <Card
              title="THE DELIVERY METHOD"
              img={biotechImg}
              text="Our non-viral delivery method is a library of cell-type-specific ionizable lipid nanoparticles (LNPs) that are optimized to induce high transgene expression in target cells and tissues. We incorporate large-scale screening of LNPs using retinal and other tissue organoids."
            />
            <Card
              title="THE CARGO"
              img={scienceImg}
              text="Our cargo is a library of minimally sized DNA minicircles with modified S/MAR elements and cell-specific synthetic compact promoters. The minicircles are optimized to carry various transgenes with no length limit theoretically."
            />
          </div>
        </div>
      ) : (
        <div className="container card-cover">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-4 mb-2">
                <Card
                  title="OUR VISION AND MISSION"
                  img={eyeImg}
                  text="Regeneron Therapeutics’ approach abandons traditional usage of viral vectors, which makes treatment intolerably expensive. We believe that our novel delivery methods and cargos will reduce development and manufacturing cost to an affordable range. We aim to provide gene therapies with price no higher than a SARS-CoV-2 jab to cover high unmet genetic treatment needs."
                />
              </div>
              <div className="col-md-4 mb-2">
                <Card
                  title="THE DELIVERY METHOD"
                  img={biotechImg}
                  text="Our non-viral delivery method is a library of cell-type-specific ionizable lipid nanoparticles (LNPs) that are optimized to induce high transgene expression in target cells and tissues. We incorporate large-scale screening of LNPs using retinal and other tissue organoids."
                />
              </div>
              <div className="col-md-4 mb-2">
                <Card
                  title="THE CARGO"
                  img={scienceImg}
                  text="Our cargo is a library of minimally sized DNA minicircles with modified S/MAR elements and cell-specific synthetic compact promoters. The minicircles are optimized to carry various transgenes with no length limit theoretically."
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default Technology;
