import { Link } from "react-scroll";

function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3">
                <h1 className="logo">Regeneron Therapeutics INC</h1>
                <p className="footer-text">
                  Empowering affordable personalized genetic medicines for
                  inherited retinal diseases
                </p>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Quick Link</p>
                <ul>
                  <li>
                    <Link
                      spy={true}
                      smooth={true}
                      duration={1000}
                      to="header-scroll"
                    >
                      {" "}
                      Home{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="technology-scroll"
                      spy={true}
                      smooth={true}
                      duration={1000}
                      offset={-70}
                    >
                      {" "}
                      Technology{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="pipeline-scroll"
                      spy={true}
                      smooth={true}
                      duration={1000}
                      offset={-70}
                    >
                      Pipeline{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="people-scroll"
                      spy={true}
                      smooth={true}
                      duration={1000}
                      offset={-70}
                    >
                      People{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="contact-scroll"
                      spy={true}
                      smooth={true}
                      duration={1000}
                    >
                      {" "}
                      Contact{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-3">
                <p className="footer-title">Contact</p>
                <ul>
                  <li>
                    <Link to="#">Mr. Lu, Ting</Link>
                  </li>
                  <li>
                    <Link to="#">ting.lu@regeneron-therapeutics.com</Link>
                    <br />
                  </li>
                  <li>
                    <br />
                  </li>
                  <li>
                    <Link to="#">Mr. Lian, Mutian</Link>
                  </li>
                  <li>
                    <Link to="#">mutian.lian@mail.utoronto.ca</Link>
                    <br />
                  </li>
                  <li>
                    <br />
                  </li>
                  <li>
                    <Link to="#">Bonneville Lab</Link>
                  </li>
                  <li>
                    <Link to="#">Hayward O1-7</Link>
                  </li>
                  <li>
                    <Link to="#">25801 Industrial Blvd</Link>
                  </li>
                  <li>
                    <Link to="#">CA 94545, US</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Social Media</p>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/regeneron-therapeutics-inc/"
                    >
                      {" "}
                      Linkedin
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-scroll"></div>
    </footer>
  );
}
export default Footer;
