import "./Person.css"; // Import the CSS file for styling

function Person(props) {
  return (
    <div className="person">
      <div className="text-center">
        <img alt="person" src={props.img} className="text-center person-img" />
      </div>
      <div className="text-center">
        <h4 className="person-name">{props.name}</h4>
      </div>
      <div className="text-center">
        <p className="person-title">{props.title}</p>
      </div>
      <div className="text-center person-details">
        {props.details.map((detail, index) => (
          <p key={index} className="person-detail">
            {detail}
          </p>
        ))}
      </div>
      <br />
    </div>
  );
}
export default Person;
