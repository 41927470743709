import React, { useState } from "react";
import { Link } from "react-scroll";
import { isMobile } from "react-device-detect";
import "./Navbar.css";
import { default as logoImg } from "../img/logo-fit.png";
import { default as closeImg } from "../img/close_48dp_FILL0_wght400_GRAD0_opsz48.svg";
import { default as menuImg } from "../img/menu_48dp_FILL0_wght400_GRAD0_opsz48.svg";

function Navbar() {
  document.addEventListener("scroll", function (e) {
    if (window.screen.width < 768 && window.scrollY > 690) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else if (window.screen.width > 768 && window.scrollY > 220) {
      const gotop = document.querySelector(".gotop");
      gotop.classList.add("display");

      const nav = document.querySelector(".navbar");

      nav.classList.add("navopened");
    } else {
      const nav = document.querySelector(".navbar");
      const gotop = document.querySelector(".gotop");
      gotop.classList.remove("display");
      nav.classList.remove("navopened");
    }
  });
  function openBar() {
    const bar = document.querySelector(".bar");

    bar.classList.toggle("opened");
  }

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

    const closeMenu = () => {
      setIsOpen(false);
    };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="row">
          <div className="logo-container">
            <Link
              spy={true}
              smooth={true}
              duration={1000}
              to="header-scroll"
              style={{ cursor: "pointer" }}
            >
              <img alt="person" src={logoImg} className="logo-img" />
            </Link>
          </div>

          {isMobile ? (
            <div className="navbar-menu-container">
              <div className="navbar-toggle" onClick={toggleMenu}>
                {isOpen ? (
                  <img src={closeImg} alt="Close Menu" />
                ) : (
                  <img src={menuImg} alt="Open Menu" />
                )}
              </div>
              <div className={`navbar-menu ${isOpen ? "open" : ""}`}>
                <a href="#header">HOME</a>
                <a href="#technology">TECHNOLOGY</a>
                <a href="#pipeline">PIPELINE</a>
                <a href="#people">PEOPLE</a>
                <a href="#footer">CONTACT</a>
              </div>
              {isOpen && <div className="overlay" onClick={closeMenu}></div>}
            </div>
          ) : (
            <ul className="bar">
              <li>
                <Link
                  onClick={openBar}
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  to="header-scroll"
                >
                  HOME
                </Link>
              </li>
              <li>
                <Link
                  onClick={openBar}
                  activeClass="active"
                  to="technology-scroll"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  offset={-70}
                >
                  TECHNOLOGY
                </Link>
              </li>
              <li>
                <Link
                  onClick={openBar}
                  to="pipeline-scroll"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                  offset={-70}
                >
                  PIPELINE
                </Link>
              </li>
              <li>
                <Link
                  onClick={openBar}
                  to="people-scroll"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                  offset={-70}
                >
                  PEOPLE
                </Link>
              </li>
              <li>
                <Link
                  onClick={openBar}
                  to="contact-scroll"
                  spy={true}
                  smooth={true}
                  duration={1000}
                  activeClass="active"
                >
                  CONTACT
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
