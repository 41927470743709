import React from "react";
import { isMobile } from "react-device-detect";
import "./Pipeline.css";

const Pipeline = () => {
    const tableData = [
      {
        therapy: "RLBP1-Retinitis Pigmentosa",
        phases: [true, true, false, false],
        stage: "Discover",
      },
      {
        therapy: "AGE-RELATED MACULAR DEGENERATION",
        phases: [true, true, false, false],
        stage: "Discover",
      },
      {
        therapy: "IDIOPATHIC PULMONARY FIBROSIS",
        phases: [true, true, false, false],
        stage: "Discover",
      },
      {
        therapy: "HEMATOPOIETIC RECOVERY",
        phases: [true, false, false, false],
        stage: "PoC",
      },
    ];

  return (
    <div className="pipeline pipeline-scroll main-section" id="pipeline">
      <h2 className="main-title text-center">PIPELINE</h2>
      <div className="container">
        <p className="pipeline-info">
          Our AI-powered promoter product can eliminate adverse effects in
          traditional genetic therapies, offering long-lasting and adjustable
          transgene expression.
        </p>
      </div>

      {isMobile ? (
        <div className="container pipeline-table">
          <h2 className="pipeline-title text-center">
            Inherited Retinal Diseases
          </h2>
          {tableData.map((row, index) => (
            <div>
              <div className="therapy-mobile therapy">{row.therapy}</div>
              <div className="table-row-mobile" key={index}>
                {row.phases.map((phase, phaseIndex) => (
                  <div
                    key={phaseIndex}
                    className={`table-cell phase ${phase ? "active" : ""}`}
                  >
                    <div className="phase-name">
                      {phaseIndex === 0 ? row.stage : ""}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="container pipeline-table">
          <h2 className="pipeline-title text-center">
            Inherited Retinal Diseases
          </h2>
          <div className="table-header">
            <div>Therapy</div>
            <div>Proof-of-concept</div>
            <div>Discover</div>
            <div>Pre-clinical</div>
            <div>IND</div>
          </div>
          {tableData.map((row, index) => (
            <div className="table-row" key={index}>
              <div className="table-cell therapy">{row.therapy}</div>
              {row.phases.map((phase, phaseIndex) => (
                <div
                  key={phaseIndex}
                  className={`table-cell phase ${phase ? "active" : ""}`}
                ></div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Pipeline;