import { isMobile } from "react-device-detect";
import Person from "./Person";
import "./People.css";
import { default as TingLuImg } from "../img/People/Ting-Lu.png";
import { default as MutianLianImg } from "../img/People/Mutian-Lian.png";
import { default as HanxinHuangImg } from "../img/People/Hanxin-Huang.png";
import { default as ZiWangImg } from "../img/People/Zi-Wang.jpg";
import { default as JiaxiChenImg } from "../img/People/Jiaxi-Chen.jpg";

function People(props) {
  return (
    <div className="people people-scroll main-section" id="people">
      <h2 className="main-title text-center">PEOPLE</h2>

      {isMobile ? (
        <div className="container">
          <div className="row-mobile">
            <div className="people-col">
              <Person
                name="Ting Lu"
                img={TingLuImg}
                title="Co-founder&CEO"
                details={[
                  "Specialized in strategic planning and in-depth market research. Wise decision driver",
                ]}
              />
            </div>
            <div className="people-col">
              <Person
                name="Mutian Lian"
                img={MutianLianImg}
                title="Director, Co-founder, Secretary & CTO"
                details={[
                  "PhD student at University College London.",
                  "Specialized in biomedical research and clinical trial management.",
                ]}
              />
            </div>
            <div className="people-col">
              <Person
                name="Hanxin Huang"
                img={HanxinHuangImg}
                title="Founder, CFO"
                details={[
                  "Sponsor of two RLBP1 projects with an total amount of $1.5M.",
                  "Patient diagnosed with Retinitis Pigmentosa.",
                ]}
              />
            </div>
            <div className="people-col">
              <Person
                name="Zi Wang"
                img={ZiWangImg}
                title="Financial Advisor"
                details={[
                  "PhD student in Finance/Economics, Columbia University.",
                  "Specialized in investment strategies and financial expertise.",
                ]}
              />
            </div>
            <div className="people-col">
              <Person
                name="Jiaxi Chen"
                img={JiaxiChenImg}
                title="Scientist"
                details={[
                  "Biochemistry/Biotechnology UG+PG student at Imperial College London.",
                  "Specialized in Biotech/Biomedical research.",
                ]}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="container card-cover">
          <div className="col-md-12">
            <div className="row people-row">
              <div className="people-col people-mobile-col mb-2">
                <Person
                  name="Ting Lu"
                  img={TingLuImg}
                  title="Co-founder&CEO"
                  details={[
                    "Specialized in strategic planning and in-depth market research. Wise decision driver",
                  ]}
                />
              </div>
              <div className="people-col people-mobile-col mb-2">
                <Person
                  name="Mutian Lian"
                  img={MutianLianImg}
                  title="Director, Co-founder, Secretary & CTO"
                  details={[
                    "PhD student at University College London.",
                    "Specialized in biomedical research and clinical trial management.",
                  ]}
                />
              </div>
              <div className="people-col people-mobile-col mb-2">
                <Person
                  name="Hanxin Huang"
                  img={HanxinHuangImg}
                  title="Founder, CFO"
                  details={[
                    "Sponsor of two RLBP1 projects with an total amount of $1.5M.",
                    "Patient diagnosed with Retinitis Pigmentosa.",
                  ]}
                />
              </div>
              <div className="people-col people-mobile-col mb-2">
                <Person
                  name="Zi Wang"
                  img={ZiWangImg}
                  title="Financial Advisor"
                  details={[
                    "PhD student in Finance/Economics, Columbia University.",
                    "Specialized in investment strategies and financial expertise.",
                  ]}
                />
              </div>
              <div className="people-col people-mobile-col mb-2">
                <Person
                  name="Jiaxi Chen"
                  img={JiaxiChenImg}
                  title="Scientist"
                  details={[
                    "Biochemistry/Biotechnology UG+PG student at Imperial College London.",
                    "Specialized in Biotech/Biomedical research.",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default People;
