import Technology from "../components/Technology";
import People from "./People";
import Pipeline from "./Pipeline";
// import Gallery from "./Gallery";

function Main() {
  return (
    <main>
      <Technology />
      <Pipeline />
      <People />
      {/* <Gallery /> */}
    </main>
  );
}
export default Main;
